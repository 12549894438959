import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2254530151/src/anetfe/node_modules/@ant-design/nextjs-registry/es/AntdRegistry.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2254530151/src/anetfe/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2254530151/src/anetfe/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2254530151/src/anetfe/node_modules/next/font/local/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"\",\"arguments\":[{\"src\":\"../../public/assets/fonts/Feather.ttf\",\"variable\":\"--font-feather\",\"weight\":\"100 900\"}],\"variableName\":\"feather\"}");
;
import(/* webpackMode: "eager", webpackExports: ["AuthStoreProvider"] */ "/codebuild/output/src2254530151/src/anetfe/src/providers/auth-store-provider.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2254530151/src/anetfe/src/style/icons/boxicons/css/boxicons.min.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2254530151/src/anetfe/src/style/icons/feather/css/iconfont.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2254530151/src/anetfe/src/style/icons/fontawesome/css/all.min.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2254530151/src/anetfe/src/style/icons/fontawesome/css/fontawesome.min.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2254530151/src/anetfe/src/style/scss/style.scss");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2254530151/src/anetfe/node_modules/antd/dist/reset.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2254530151/src/anetfe/node_modules/slick-carousel/slick/slick-theme.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2254530151/src/anetfe/node_modules/slick-carousel/slick/slick.css");
